<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'8'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>设置</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>收货地址</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚海报</span>
        </div>
        <div class="left-list-item" @click="leftClick('2')">
          <span>闲赚比例</span>
        </div>
        <div class="left-list-item" @click="leftClick('3')">
          <span>驳回原因</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('4')">
          <span>客服</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>客服列表</span>
        </div>
        <div class="mt-20">
          <div class="date-search">
            <el-button type="primary" @click="customerAdd">添加</el-button>
          </div>
          <div class="mt-20"></div>
          <el-table :data="tableData.data" border style="width: 100%">
            <el-table-column
              prop="id"
              label="客服ID"
              width="150"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="date"
              label="头像"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <img
                  style="width: 100%"
                  :src="'http://weixin.maibaobao.shop/' + scope.row.avatar"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="nick_name"
              label="昵称"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="wx_number"
              label="微信号"
              width="250"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  style="width: 120px"
                  @click="handleEdit(scope.$index, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  style="width: 120px"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="bottom-paging">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[5, 10, 20, 30, 100]"
              :page-size="num"
              layout="total, prev, pager, next, sizes"
              :total="tableData.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="编辑客服信息"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <el-upload
         ref="upload"
        class="upload-demo"
        :action="$store.state.httpUrl + 'edit/type'"
        list-type="picture"
        :auto-upload="false"
        :multiple="false"
        :show-file-list="false"
        :headers="uploadHeaders"
        :http-request="uploadFile"
        :limit="1"
        :on-exceed="uploadExceed"
        :on-change="uploadChange"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div class="upload-img">
        <img :src="bjformList.file ? bjformList.file : 'http://weixin.maibaobao.shop/' + avatar" alt="" />
      </div>
      <div class="mt-20">
        <span class="txt-red">*</span>
        <span class="xg-title">昵称：</span>
        <el-input
          placeholder="请输入昵称"
          class="xg-input"
          v-model="bjformList.nick_name"
        ></el-input>
      </div>

      <div class="mt-20">
        <span class="txt-red">*</span>
        <span class="xg-title">微信号：</span>
        <el-input
          placeholder="请输入微信号"
          class="xg-input"
          v-model="bjformList.wx_number"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bjSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="新增客服"
      :visible.sync="centerDialogVisible2"
      width="700px"
      center
    >
      <el-upload
        ref="upload"
        class="upload-demo"
        :action="$store.state.httpUrl + 'edit/type'"
        list-type="picture"
        :auto-upload="false"
        :multiple="false"
        :show-file-list="false"
        :headers="uploadHeaders"
        :http-request="inseruploadFile"
        :on-change="inserUploadchange"
        :limit="1"
        :on-exceed="uploadExceed"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div class="upload-img" v-if="fromListin.file">
        <img :src="fromListin.file" alt="" />
      </div>
      <div class="mt-20">
        <span class="txt-red">*</span>
        <span class="xg-title">昵称：</span>
        <el-input
          placeholder="请输入昵称"
          class="xg-input"
          v-model="fromListin.nick_name"
        ></el-input>
      </div>

      <div class="mt-20">
        <span class="txt-red">*</span>
        <span class="xg-title">微信号：</span>
        <el-input
          placeholder="请输入微信号"
          class="xg-input"
          v-model="fromListin.wx_number"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue";
export default {
  components: {
    leftList,
    topHeader,
  },
  inject: ["reload"],
  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisible2: false,
      tableData: [],
      value: "",
      isCollapse: false,
      fromListin: {
        nick_name: "",
        wx_number: "",
        file: "",
      },
      avatar: "",
      bjformList: {
        id: "",
        nick_name: "",
        wx_number: "",
        file: "",
      },
      uploadHeaders: {
        "Remember-Token": localStorage.getItem("rememberToken"),
      },
      xgInfo: {},
      page: 1,
      num: 10,
      formDate: "",
      inserformdata: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    uploadChange(file) {
      // console.log(file, fileList, index);
      this.bjformList.file = URL.createObjectURL(file.raw);
      // console.log(this.fromList.file);
    },
    inserUploadchange(file) {
      console.log(file);
      this.fromListin.file = URL.createObjectURL(file.raw);
      // console.log(this.fromList.file);
    },
    uploadFile(file) {
      this.formDate.append("avatar", file.file);
    },
    inseruploadFile(file) {
      this.inserformdata.append("avatar", file.file);
    },
    bjSubmit() {
      if (this.bjformList.nick_name == "") {
        this.$notify.error({
          title: "提示",
          message: "昵称不可为空！",
        });
        return false;
      }
      if (this.bjformList.wx_number == "") {
        this.$notify.error({
          title: "提示",
          message: "微信号不可为空！",
        });
        return false;
      }
      this.formDate = new FormData();
      this.$refs.upload.submit();
      this.formDate.append("id", this.bjformList.id);
      this.formDate.append("nick_name", this.bjformList.nick_name);
      this.formDate.append("wx_number", this.bjformList.wx_number);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post("http://weixin.maibaobao.shop/api/upd/staff", this.formDate, config)
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.reload();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    uploadExceed() {
      this.$notify({
        title: "警告",
        message: "只可选择一张图片",
        type: "warning",
      });
    },
    submitUpload() {
      if (this.fromListin.nick_name == "") {
        this.$notify.error({
          title: "提示",
          message: "昵称不可为空！",
        });
        return false;
      }
      if (this.fromListin.file == "") {
        this.$notify.error({
          title: "提示",
          message: "请上传客服图片！",
        });
        return false;
      }
      if (this.fromListin.wx_number == "") {
        this.$notify.error({
          title: "提示",
          message: "微信号不可为空！",
        });
        return false;
      }
      this.inserformdata = new FormData();
      this.$refs.upload.submit();
      this.inserformdata.append("nick_name", this.fromListin.nick_name);
      this.inserformdata.append("wx_number", this.fromListin.wx_number);
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      this.$axios
        .post("http://weixin.maibaobao.shop/api/add/staff", this.inserformdata, config)
        .then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "提示",
              message: "操作成功！",
              type: "success",
            });
            this.reload();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
            });
          }
          // console.log(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getList() {
      this.$get("get/staff", {}).then((res) => {
        this.tableData = res.data.data;
      });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/setup") {
        this.$router.push("/setup");
      } else if (index == 1 && this.$route.path != "/poster") {
        this.$router.push("/poster");
      } else if (index == 2 && this.$route.path != "/proportion") {
        this.$router.push("/proportion");
      } else if (index == 3 && this.$route.path != "/reject") {
        this.$router.push("/reject");
      } else if (index == 4 && this.$route.path != "/customer") {
        this.$router.push("/customer");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
    handleEdit(index, row) {
      this.avatar = row.avatar;
      this.bjformList.id = row.id;
      this.bjformList.wx_number = row.wx_number;
      this.bjformList.nick_name = row.nick_name;
      this.centerDialogVisible = true;
      //   console.log(index, row);
    },
    customerAdd() {
      this.centerDialogVisible2 = true;
    },
    handleDelete(index, row) {
      this.$confirm("是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$post("del/staff", {
            id: row.id,
          }).then((res) => {
            if (res.data.status == 200) {
              this.$notify({
                title: "提示",
                message: "操作成功！",
                type: "success",
              });
              this.reload();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      console.log(index, row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.xg-input {
  width: 300px;
}
.xg-title {
  display: inline-block;
  width: 80px;
}
.upload-img {
  display: inline-block;
  width: 100px;
  margin-top: 20px;
  text-align: center;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.upload-title {
  margin-left: 100px;
  font-size: 14px;
}
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .date-search {
    text-align: right;
    .el-button {
      padding: 12px 55px;
    }
  }
  .address-from {
    margin-top: 30px;
    padding-left: 150px;
    .from-item {
      margin-top: 50px;
      .from-label {
        display: inline-block;
        width: 200px;
      }
      .from-item-select {
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-input {
        display: inline-block;
        width: 200px;
        border-bottom: 1px solid #bbb5b5;
        margin-left: 30px;
        input {
          width: 190px;
          border: 0px;
          outline: none;
        }
      }
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
